import axios, { AxiosRequestConfig } from 'axios';

const restApi = axios.create({
  baseURL: process.env.REACT_APP_HOST_ENV === 'development' ? 'https://api.dev.letr.ai' : 'https://api.letr.ai',
});

restApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  return config;
});

export const getFailureList = async () => {
  const response = await restApi.get('/health-check-failures');
  console.log('response: ', response);
  return response.data;
};
