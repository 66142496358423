import { Theme, ThemeOptions } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

export interface ExtendedTheme extends Theme {
  drawerWidth: number;
}

export interface ExtendedThemeOptions extends ThemeOptions {
  drawerWidth: number;
}

export const theme = createMuiTheme({
  drawerWidth: 240,
  palette: {
    primary: {
      main: '#ffe600',
    },
    secondary: {
      main: '#112059',
    },
    action: {
      disabled: 'white',
      disabledBackground: 'grey',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        overflowWrap: 'anywhere',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
} as ExtendedThemeOptions);
