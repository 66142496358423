import './App.css';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getFailureList } from './utils/api';

interface FailureData {
  id: number;
  failedAt: number;
  endpoint: string;
}
const App = () => {
  const [failureData, setFailureData] = useState<FailureData[]>([]);
  useEffect(() => {
    fetchFailures();
  }, []);

  const fetchFailures = async () => {
    setFailureData(await getFailureList());
  };

  console.log('failureData: ', failureData);
  return (
    <div className="incident-container">
      <h1>Incidents</h1>
      <div>
        {failureData?.map((item) => {
          return (
            <div className="item-container" key={item.id}>
              <div className="datetime">{moment(item.failedAt).format()}</div>
              <hr />
              <div className="endpoint">
                <b>{item.endpoint}</b> did not pass the health check test
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default App;
